<template>
  <div v-if="coupon" class="coupon-section">
    <WizardForm
      v-if="coupon"
      :coupon="coupon"
      @coupon-changed="couponChanged"
    ></WizardForm>
  </div>
</template>

<script>
import WizardForm from './WizardForm'
import moment from 'moment-timezone'

export default {
  components: {
    WizardForm,
  },

  data() {
    return {
      showColorModal: false,
      couponType: null,
      selectedTab: null,
      selectedMode: 'design',
      coupon: null,
      loadingCreate: false,
    }
  },

  computed: {
    listSections() {
      return this.sections.filter(
        (section) => section.categories.indexOf(this.coupon.type) !== -1
      )
    },

    discountTypes() {
      return this.$store.getters['coupon/discountTypes']
    },

    user() {
      return this.$store.getters['auth/user']
    },
  },

  mounted() {
    this.coupon = JSON.parse(
      JSON.stringify(this.$store.getters['coupon/couponOriginal'])
    )
    this.coupon.sections.mobilebutton.Number = this.user.business.phone_number
    this.coupon.timezone = moment.tz.guess()
  },

  methods: {
    selectMode(mode) {
      this.selectedMode = mode
    },

    onTabClick(section) {
      this.selectedTab = section
    },

    create() {
      this.loadingCreate = true
      this.coupon.campaign_id = this.coupon.campaign
        ? this.coupon.campaign.id
        : null

      this.$store
        .dispatch('coupon/create', this.coupon)
        .then((res) => {
          this.loadingCreate = false
          this.$router.push({ name: 'business.coupons.index' })
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.createForm.setErrors(err.response.data.errors)
          }
          this.loadingCreate = false
        })
    },

    couponChanged(coupon) {
      this.coupon = {
        new_keyword: this.coupon.new_keyword,
        automation_id: this.coupon.automation_id,
        ...coupon,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  max-width: 1024px;
}

.preview-container {
  display: flex;
  justify-content: center;
}

.preview-actions {
  margin-top: 30px;
  border-top: 1px solid rgba(204, 204, 204, 0.5);
  padding-top: 30px;
  .btn {
    width: 150px;
  }
}
</style>
