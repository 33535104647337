<template>
  <form-wizard
    color="#4284f4"
    @on-change="handleOnChange"
  >
    <tab-content
      title="Create campaign"
      :before-change="() => $refs.createCampaignStep.validateStep()"
    >
      <CreateCampaignStep
        ref="createCampaignStep"
        :coupon="coupon"
        @done="(coupon) => $emit('coupon-changed', coupon)"
        @loading="loadingState"
      />
    </tab-content>
    <!-- <tab-content
      title="Messages and auto responder"
      :before-change="() => $refs.autoResponderStep.validateStep()"
    >
      <AutoResponderStep
        ref="autoResponderStep"
        :coupon="coupon"
        @done="(coupon) => $emit('coupon-changed', coupon)"
        @loading="loadingState"
      />
    </tab-content> -->
    <tab-content
      title="Coupon Wizard"
      :before-change="() => $refs.couponWizardStep.validateStep()"
    >
      <CounponWizardStep
        ref="couponWizardStep"
        :coupon="coupon"
        @done="(coupon) => $emit('coupon-changed', coupon)"
        @loading="loadingState"
      />
    </tab-content>
    <tab-content title="Code Generation">
      <CodeGenerationStep :coupon-script="couponScript" :coupon="coupon" />
    </tab-content>
    <template slot="next">
      <button
        tabindex="-1"
        type="button"
        class="wizard-btn"
        style="color: #fff;background-color: #4284f4;border-color: #4284f4;"
        :disabled="isLoading"
      >
        <b-spinner v-if="isLoading" small />
        <span v-else>Next</span>
      </button>
    </template>
    <template slot="finish">
      <router-link
        v-if="!coupon.new_keyword && coupon.automation_id"
        :to="{ name: 'business.automations.edit', params: { id: coupon.automation_id } }"
        class="wizard-btn ml-2" style="color: #fff;background-color: #4284f4;border-color: #4284f4;">
        Go To Automation
      </router-link>
      <a
        href="javascript:;"
        class="wizard-btn ml-2"
        style="color: #fff;background-color: #4284f4;border-color: #4284f4;"
        @click.prevent="handleOnComplete"
      >
        Finish
      </a>
    </template>
  </form-wizard>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import CodeGenerationStep from './Components/CodeGenerationStep'
import CreateCampaignStep from './Components/CreateCampaignStep'
import CounponWizardStep from './Components/CounponWizardStep'

export default {
  components: {
    FormWizard,
    TabContent,
    CodeGenerationStep,
    CreateCampaignStep,
    CounponWizardStep,
  },

  props: {
    coupon: {
      type: [Object],
      default: null,
    },
  },

  data() {
    return {
      isLoading: false,
      couponScript: null,
    }
  },

  mounted() {
    if (this.coupon.type === 'popup') {
      if (!this.coupon.points) {
        this.coupon.points = {
          OptIn: { active: true, points: 1 },
          ReferralOptIn: { active: true, points: 1 },
          ShareText: { active: false, points: 1, text: 'Check out this cool offer that i just found Follow this link. To check it out', label: 'Share Text', itemBackground: '#548235', itemColor: '#FFFFFF' },
          ClickFacebook: { active: false, points: 1, label: 'Share on Facebook', itemBackground: '#3b5998', itemColor: '#FFFFFF' },
          ClickTwitter: { active: false, points: 1, label: 'Share on Twitter', itemBackground: '#1da1f2', itemColor: '#FFFFFF'   },
          ClickLinkedin: { active: false, points: 1, label: 'Share on Linkedin', itemBackground: '#007bb5', itemColor: '#FFFFFF'   },
          ClickPinterest: { active: false, points: 1, label: 'Share on Pinterest', itemBackground: '#cb2027', itemColor: '#FFFFFF'   },
          ShareFacebook: { active: false, points: 1, image: '', label: 'Like our Facebook Page', itemBackground: '#3b5998', itemColor: '#FFFFFF'  },
          ShareTwitter: { active: false, points: 1, image: '', label: 'Follow us on Twitter', itemBackground: '#1da1f2', itemColor: '#FFFFFF'  },
          ShareLinkedin: { active: false, points: 1, image: '', label: 'Follow us on Linkedin', itemBackground: '#007bb5', itemColor: '#FFFFFF'  },
          SharePinterest: { active: false, points: 1, image: '', label: 'Follow us on Pinterest', itemBackground: '#cb2027', itemColor: '#FFFFFF'  },
        }
      }
      
      if (!this.coupon.loops) {
        let landingOriginal = JSON.parse(
          JSON.stringify(this.$store.getters['landing/landingOriginal'])
        )

        this.coupon.loops = landingOriginal.loops

        this.coupon.loops.styling = {
          ...this.coupon.loops.styling,
          BackgroundImage: "https://images.unsplash.com/photo-1481437156560-3205f6a55735?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxMzY3ODV8MHwxfHNlYXJjaHw5fHxyZXRhaWx8ZW58MHwwfHw&ixlib=rb-1.2.1&q=80&w=1080",
          BackgroundVideo: null,
        }

        this.coupon.loops['legalFooter'] = {
          ...landingOriginal.sections.legalFooter
        }
      }
    }
  },

  methods: {
    loadingState(loading) {
      this.isLoading = loading
    },

    getScript() {
      this.$store
        .dispatch('coupon/getScript', this.coupon.id)
        .then((script) => {
          this.couponScript = script
        })
        .catch(() => {})
    },

    handleOnChange(prevIndex, nextIndex) {
      if (nextIndex === 1 && !this.couponScript) {
        this.getScript()
      }
    },

    handleOnComplete() {
      if (this.coupon.new_keyword && this.coupon.automation_id) {
        this.$router.push({ name: 'business.automations.edit', params: { id: this.coupon.automation_id } })
      } else {
        this.$router.push({ name: 'business.coupons.index' })
      }
    },
  },
}
</script>
